import { ActivityProps } from "types";
import { capitalize, getFormatedDateTime } from "utils";

export interface StyledActivityValueProps {
  activity: ActivityProps;
  valueKey: keyof ActivityProps;
}

const StyledActivityValue: React.FC<StyledActivityValueProps> = ({
  activity,
  valueKey,
}) => {
  return (
    <>
      {(() => {
        switch (valueKey) {
          case "change":
            return Object.keys(activity?.[valueKey])?.join(", ");

          case "updated_user":
            return [
              activity?.["updated_first_name"],
              activity?.["updated_last_name"],
            ]?.join(" ");

          case "update_timestamp":
            return getFormatedDateTime(activity?.update_timestamp, {
              dateStyle: "medium",
              timeStyle: "short",
            });

          case "type":
            if (!activity?.type) return <>-</>;
            return capitalize(activity?.type);

          default:
            if (activity?.hasOwnProperty(valueKey)) {
              return <>{(activity as any)?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledActivityValue;
