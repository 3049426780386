import { ArrowBackOutlined } from "@material-ui/icons";
import {
  SolButton,
  SolDropdown,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getIndustryAPI,
  getMarketAPI,
  getServiceAPI,
  patchProfileAPI,
} from "services";
import { BrokerProps, ProfileProps } from "types";
import { SelectAutoComplete, Switch, Toast } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import * as Yup from "yup";
import cn from "classnames";

interface MarketProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker: BrokerProps | null;
}

const INITIAL_VALUES = {
  industries: [],
  property_type: [],
  services: [],
  job_title: "",
};

const validationSchema = Yup.object().shape({
  industries: Yup.array()
    .required("This field is required")
    .max(2, "The industries should be limited to a maximum of 2 entries."),
  services: Yup.array()
    .required("This field is required")
    .max(2, "The services should be limited to a maximum of 2 entries."),
  property_type: Yup.array()
    .required("This field is required")
    .max(2, "The property types should be limited to a maximum of 2 entries."),
});

const Market: React.FC<MarketProps> = ({
  profile,
  setProfile,
  isLoading,
  broker,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getIndustry] = useApiCall(getIndustryAPI);
  const [getService] = useApiCall(getServiceAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [isGlobal, setIsGlobal] = useState(false);

  const typeOptions = Object.keys(
    meta?.["brokerprofile"]?.["property_type"] || []
  )?.map((key: number | string) => ({
    value: key,
    label: meta?.["brokerprofile"]?.["property_type"]?.[key]?.value,
  }));

  const { handleBlur, setFieldValue, values, setValues, touched, errors } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "property_type") {
        formValues = {
          ...formValues,
          [key]: typeOptions?.filter((option: any) =>
            profile?.property_type?.includes(option?.value)
          ),
        };
        return;
      }
      if (key === "job_title") {
        formValues = {
          ...formValues,
          [key]: profile?.job_title,
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: ((profile as any)?.[key] || [])?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        })),
      };
    });
    setValues({
      ...formValues,
    });
  }, [profile]);

  const loadIndustryOptions = async (keyword: string) => {
    return await getIndustry({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
      active_status: [STATUS_ACTIVE],
    }).then((res: any) => {
      return res.docs.map((industry: any) => {
        return {
          value: industry.pk,
          label: industry?.name,
        };
      });
    });
  };

  const loadServiceOptions = async (keyword: string) => {
    return await getService({
      keyword,
      limit: SEARCH_RESULT_LIMIT,
      page: 1,
      active_status: [STATUS_ACTIVE],
    }).then((res: any) => {
      return res.docs.map((service: any) => {
        return {
          value: service.pk,
          label: service?.name,
        };
      });
    });
  };

  const handleUpdate = (
    key: "industries" | "services" | "property_type" | "job_title",
    values: any
  ) => {
    try {
      if (key !== "job_title" && values?.length > 2) {
        Toast.warn(`The ${key} should be limited to a maximum of 2 entries.`);
        return;
      }
      setFieldValue(key, values);
      let promises = [];
      if (isGlobal) {
        broker?.profiles?.forEach((item) => {
          promises.push(
            patchProfile({
              pk: item?.id,
              [key]:
                key === "job_title"
                  ? values?.job_title
                  : values?.map((item: any) => item?.value),
            })
          );
        });
      } else {
        promises.push(
          patchProfile({
            pk: profile?.id,
            [key]:
              key === "job_title"
                ? values?.job_title
                : values?.map((item: any) => item?.value),
          })
        );
      }

      Promise.all(promises).then((res_list) => {
        if (!res_list?.length) return;
        const current_res = res_list.find(
          (item) => item?.id?.toString() === profile?.id?.toString()
        );
        if (!current_res) return;
        setProfile({
          ...profile,
          [key]: current_res?.[key],
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div
            className={cn("mb-12", {
              "!mb-4":
                !!broker?.profiles?.length && broker?.profiles?.length > 1,
            })}
          >
            <h2 className="text-xl mb-1">Roles / Tags</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Enter your relevant industries and services.
            </p>
          </div>
          {!!broker?.profiles?.length && broker?.profiles?.length > 1 && (
            <div className="flex flex-row items-center mb-8">
              Make these adjustments for other profiles for this broker?{" "}
              <Switch
                enabled={isGlobal}
                onChange={() => setIsGlobal((prev) => !prev)}
                className="!mb-0 ml-4"
              />
            </div>
          )}
        
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-8">
            <SelectAutoComplete
              name="industries"
              label="Industry"
              loadOptions={loadIndustryOptions}
              isMulti
              size="sm"
              value={values.industries}
              onChange={(values) => {
                handleUpdate("industries", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
              }}
              className="cols-span-1 w-full"
              isCustomMenu
              error={touched?.industries ? errors?.industries : ""}
            />
            <SelectAutoComplete
              name="services"
              label="Services"
              loadOptions={loadServiceOptions}
              isMulti
              size="sm"
              value={values.services}
              onChange={(values) => {
                handleUpdate("services", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
              }}
              className="cols-span-1 w-full"
              error={touched?.services ? errors?.services : ""}
            />
            <SelectAutoComplete
              name="property_type"
              label="Property Type"
              options={typeOptions}
              isMulti
              size="sm"
              value={values?.property_type}
              onChange={(values) => {
                handleUpdate("property_type", values);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
              }}
              className="cols-span-1 w-full"
              error={touched?.property_type ? errors?.property_type : ""}
            />
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-14">
            <div className="col-span-1">
              <p className="text-xl mb-1">Job Title</p>
              <p className="text-sm text-jll-text-base-subdued mb-2">
                Your role at JLL. Please use your official role title.{" "}
              </p>
              <SolTextField
                value={values.job_title}
                onSol-input={(e: any) => setFieldValue("job_title", e?.detail)}
                id="job_title"
                onBlur={(e) => {
                  handleBlur(e);
                  handleUpdate("job_title", values.job_title);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate("job_title", values.job_title);
                  }
                }}
                size="small"
                errorLabel={touched?.job_title ? errors?.job_title : ""}
              />
            </div>
          </div>

          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "geography");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "awards");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Market;
