import { SearchOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT } from "constant";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getActivityAPI } from "services";
import { ActivityProps } from "types";
import { Input } from "ui-atoms";
import { StyledActivityValue, StyledActivityValueProps, Table } from "ui-molecules";
import { getDecimalFormating } from "utils";

const LOG_TABLE_COLUMNS: {
  id: keyof ActivityProps,
  label: string
}[] = [
  {
    id: "change",
    label: "Field Change",
  },
  {
    id: "type",
    label: "Status",
  },
  {
    id: "updated_user",
    label: "Updated by",
  },
  {
    id: "update_timestamp",
    label: "Updated on",
  },
];

const ProfileLog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getActivity] = useApiCall(getActivityAPI);
  const { brokerId } = useParams();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getActivity({
      id: brokerId,
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      // active_status: [STATUS_ACTIVE],
      ...paramFilters,
      keyword: "w",
    }).then((res: any) => {
      if (!res) return;
      setData(res);
    });
  }, [brokerId]);

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-hidden">
        <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between flex-wrap">
          <div className="flex flex-row items-center space-x-3"></div>
          <div>
            <Input
              leadingIcon={SearchOutlined}
              isClearable
              onChange={onChangeInput}
              value={keyword}
              className="w-[280px] !mb-0"
              placeholder="Search by name, country, city..."
              label="Search"
            />
          </div>
        </div>

        <div className="flex flex-col mx-10 relative">
          <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
            <span>{`${getDecimalFormating(total)} result${
              total > 1 ? "s" : ""
            }`}</span>
          </div>
        </div>

        <div className="flex flex-1 w-full h-full flex-col px-10 overflow-auto">
          {isFirstLoading ? (
            <Table.Loading size={4} />
          ) : (
            <>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {LOG_TABLE_COLUMNS.map((column, idx) => (
                      <Table.Th key={idx}>{column.label}</Table.Th>
                    ))}
                    <Table.Th>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data?.map((item, idx) => (
                    <Table.Tr key={idx}>
                      {LOG_TABLE_COLUMNS.map((column, idx1) => (
                        <Table.Td key={idx1}>
                          <StyledActivityValue
                            activity={item}
                            valueKey={column.id}
                          />
                        </Table.Td>
                      ))}
                      <Table.Td>
                        <SolButton variant="secondary">
                          All activities
                          <SolIcon icon="arrow_right_alt" />
                        </SolButton>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileLog;
